html {
    scroll-behavior: smooth;
    background-color: #111827;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.blur {
    backdrop-filter: blur(20px);
    opacity: 0.8;
}

.stroke {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
}
svg,
svg * {
    pointer-events: none;
}
object {
    pointer-events: none;
}
